/* loading */
.snippet {
    display: none;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #020202;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.list-product-thumb-inner {
    overflow: hidden;
}

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 32px 0;
    margin: 0 -5%;
    overflow: hidden;
}

.dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #6F6F6F, 12.727926px -12.727926px 0 0 #6F6F6F, 18px 0 0 0 #6F6F6F, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
    animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {

    0%,
    100% {
        box-shadow: 0 -18px 0 0 #6F6F6F, 12.727926px -12.727926px 0 0 #6F6F6F, 18px 0 0 0 #6F6F6F, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #6F6F6F, 18px 0 0 0 #6F6F6F, 12.727926px 12.727926px 0 0 #6F6F6F, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #6F6F6F, 12.727926px 12.727926px 0 0 #6F6F6F, 0 18px 0 0 #6F6F6F, -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #6F6F6F, 0 18px 0 0 #6F6F6F, -12.727926px 12.727926px 0 0 #6F6F6F, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #6F6F6F, -12.727926px 12.727926px 0 0 #6F6F6F, -18px 0 0 0 #6F6F6F, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }

    62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #6F6F6F, -18px 0 0 0 #6F6F6F, -12.727926px -12.727926px 0 0 #6F6F6F;
    }

    75% {
        box-shadow: 0 -18px 0 0 #6F6F6F, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #6F6F6F, -12.727926px -12.727926px 0 0 #6F6F6F;
    }

    87.5% {
        box-shadow: 0 -18px 0 0 #6F6F6F, 12.727926px -12.727926px 0 0 #6F6F6F, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 #6F6F6F;
    }
}

#page_product_list .section-cat-list li {
    word-break: break-word;
}

.list-tags a, .block-product-thumb span{
    word-break: break-word;
}

#page_homepage #ranking-section-lazy li button, .product_page #ranking-section-lazy li button, #page_ranking_list .modify-ranking li button {
    height: max-content !important;
    line-height: 1.4 !important;
    padding: 13px 16px;
    font-size: 1.4rem;
}

.product_page .section-cat-list li a {
    height: max-content !important;
    word-break: break-word !important;
}